import React, { useEffect } from "react";
import { withPage } from "../PageContainer";
import { Link } from "gatsby";
import SEO from "../Components/seo";
import { navigate } from "gatsby";

const NotFoundPage = props => {
  useEffect(() => {
    if (props.location.pathname.indexOf("/en") !== -1) {
      navigate("/en"); // redirecting to home page
    } else {
      navigate("/"); // redirecting to home page
    }
  }, []);
  return <div />;
};

export default withPage(NotFoundPage);
